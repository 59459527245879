.leader-line {
  z-index: -1;
}

.to-hide {
  opacity: 0;
  transition: opacity 0.3s linear !important;
}

.output-drag .input-drop {
  background-color: #8fcdff;
}
